<template>
  <div
    :class="{
      'mobile': !isDesktop,
      'premium': user && user.group === 777,
    }"
    class="main-layout"
  >
    <Garland v-if="christmasTime" />

    <Mobile v-if="!isDesktop" />

    <!-- <Snow v-if="showSnow" /> -->

    <transition name="toBottom">
      <header v-if="$route.name !== 'rooms-id'" class="shpr">
        <nuxt-link to="/catalog" tag="button" class="wi ctlg">
          <img src="/static/media/icons/products-dark.png" alt="">
          <span v-if="isDesktop">Каталог</span>
        </nuxt-link>

        <SH v-if="showSH" :type="`db`" />
        <div v-else></div>
        
        <Profile />
      </header>
    </transition>

    <transition name="toLeft">
      <Recommends v-if="showRecommends" />
    </transition>

    <!-- <transition name="toTop">
      <nuxt />
    </transition> -->
    <nuxt />

    <Error v-if="error" :error="error" />

    <div v-if="$route.name !== 'rooms-id'" id="liveinternet">
      <!--LiveInternet counter--><a href="https://www.liveinternet.ru/click"
      target="_blank"><img id="licnt4208" width="31" height="31" style="border:0" 
      title="LiveInternet"
      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAIBTAA7"
      alt=""/></a><!--/LiveInternet-->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'

Vue.mixin(global)

export default {
  components: {
    // Snow: () => import('~/components/Snow.vue'),
    Garland: () => import('~/components/Garland.vue'),
    Mobile: () => import('~/components/Mobile.vue'),
    Error: () => import('~/components/Error.vue'),
    SH: () => import('~/components/SH.vue'),
    Profile: () => import('~/components/Profile.vue'),
    Recommends: () => import('~/components/Recommends.vue'),
  },
  watch: {
    fullPath(val) {
      document.body.style.overflow = ''
    },
  },
  computed: {
    ...mapState(['error', 'isDesktop', 'user', 'tabActive']),
    fullPath() {
      return this.$route.fullPath
    },
    showSH() {
      return this.$route?.name && !this.$route.name.includes('articles')
    },
    showRecommends() {
      return this.$route?.name && !this.$route.name.includes('rooms-id')
    },
  },
  data() {
    const christmasTime = (new Date().getMonth() === 11 && new Date().getDate() > 15) || (new Date().getMonth() === 0 && new Date().getDate() < 15)

    return {
      christmasTime
      // showSnow: true,
      // loading: false,
    }
  },
  mounted() {
    if (this.$route.query.ref) {
      localStorage.setItem('referer', this.$route.query.ref)
    } else if (this.user && localStorage.getItem('referer') && localStorage.getItem('referer').length) {
      this.$axios.post(`/api/user/ref`, { referer: localStorage.getItem('referer') })
      localStorage.setItem('referer', '')
    }

    const vm = this

    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'visible') {
        console.log('Вкладка активна')
        vm.$store.commit('setTabActive', 1)
      } else {
        console.log('Вкладка неактивна')
        vm.$store.commit('setTabActive', 0)
      }
    })

    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    window.addEventListener('resize', () => { 
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    })

    // this.showAds()

    this.innerLI()
  },
  methods: {
    innerLI() {
      const script = document.createElement('script')
      script.innerHTML = `(function(d,s){d.getElementById("licnt4208").src=
"https://counter.yadro.ru/hit?t50.1;r"+escape(d.referrer)+
((typeof(s)=="undefined")?"":";s"+s.width+"*"+s.height+"*"+
(s.colorDepth?s.colorDepth:s.pixelDepth))+";u"+escape(d.URL)+
";h"+escape(d.title.substring(0,150))+";"+Math.random()})
(document,screen)`

      if (document.getElementById('liveinternet')) {
        document.getElementById('liveinternet').append(script)
      }
    },
    showAds() {
      if (!this.user || this.user.group === 1) {
        const vm = this
        
        setInterval(() => {
          vm.showVideoAd = true
        }, 5 * 60 * 1000)
        
        const adfinity = document.createElement('script')
        adfinity.async = true
        adfinity.src = `https://cdn.adfinity.pro/code/silverpixel.ru/adfinity.js`
        document.head.appendChild(adfinity)

        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_R-A-6711067-1',
    blockId: 'R-A-6711067-1'
    })
  })`
          document.body.appendChild(rsyaHorizon)

          const rsyaInf = document.createElement('script')
          rsyaInf.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
  renderTo: 'yandex_rtb_R-A-6711067-6',
  blockId: 'R-A-6711067-6'
  })
})`
          document.body.appendChild(rsyaInf)

          if (!this.isDesktop) {
            const rsyaFloorAd = document.createElement('script')
            rsyaFloorAd.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
  "blockId": "R-A-6711067-3",
  "type": "floorAd",
  "platform": "touch"
  })
})`
//             rsyaFloorAd.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.render({
//   "blockId": ${this.isDesktop ? '"' + 'R-A-6711067-7' + '"' : '"' + 'R-A-6711067-3' + '"'},
//   "type": "floorAd",
//   "platform": ${this.isDesktop ? '"' + 'desktop' + '"' : '"' + 'touch' + '"'}
//   })
// })`
            document.body.appendChild(rsyaFloorAd)

            const rsyaFullscreen = document.createElement('script')
            rsyaFullscreen.innerHTML = `
window.yaContextCb.push(() => {
  Ya.Context.AdvManager.render({
      "blockId": "R-A-6711067-4",
      "type": "fullscreen",
      "platform": "touch"
  })
})`
//           rsyaFullscreen.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.render({
//     "blockId": ${this.isDesktop ? '"' + 'R-A-6711067-5' + '"' : '"' + 'R-A-6711067-4' + '"'},
//     "type": "fullscreen",
//     "platform": ${this.isDesktop ? '"' + 'desktop' + '"' : '"' + 'touch' + '"'}
//   })
// })`
            // document.body.appendChild(rsyaFullscreen)
          } else {
//           const rsyaDesktopVideoAd = document.createElement('script')
//           rsyaDesktopVideoAd.innerHTML = `
// window.yaContextCb.push(()=>{
// Ya.Context.AdvManager.render({
// renderTo: 'yandex_rtb_R-A-2003630-3',
// blockId: 'R-A-2003630-3'
// })
// })`
//           document.body.appendChild(rsyaDesktopVideoAd)
          }
        }, 200)
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      location.reload()
      // this.$store.commit('setUser', null)
      // location.href = '/signin'
    },
  }
}
</script>

<style>
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus, textarea:focus, input:focus {
      font-size: 16px;
    }
  }
</style>
