const middleware = {}

middleware['auth-admin'] = require('../middleware/auth-admin.js')
middleware['auth-admin'] = middleware['auth-admin'].default || middleware['auth-admin']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['isauth'] = require('../middleware/isauth.js')
middleware['isauth'] = middleware['isauth'].default || middleware['isauth']

export default middleware
